// import React from 'react';
import './App.css';
// import Routes from 'routes';
// project imports
import NavigationScroll from 'layout/NavigationScroll';
import Snackbar from 'ui-component/Snackbar';
import ThemeCustomization from 'themes';
import Routes from 'routes';

// auth provider
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
const App = () => (
    <ThemeCustomization>
        {/* RTL layout */}
        <NavigationScroll>
            <AuthProvider>
                <>
                    <Routes />
                    <Snackbar />
                </>
            </AuthProvider>
        </NavigationScroll>
    </ThemeCustomization>
);

export default App;
