import { Stack } from '@mui/material';
import { Outlet } from 'react-router-dom';
import Footer from 'ui-component/Footer';
import HeaderLogo from 'ui-component/HeaderLogo';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout = () => (
    <>
        <HeaderLogo />
        <Stack sx={{ marginBottom: { xs: '100px', md: '50px' } }}>
            <Outlet />
        </Stack>
        <Footer />
    </>
);

export default MinimalLayout;
