import { Suspense, ElementType } from 'react';

// project imports
import Loader from './Loader';
import { KeyedObject } from 'types';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (Component: ElementType) =>
    function (props: KeyedObject) {
        return (
            <Suspense fallback={<Loader />}>
                <Component {...props} />
            </Suspense>
        );
    };

export default Loadable;
