import { Task } from 'types/portal';

export const formatPhoneNumber = (phoneNumberString: string) => {
    const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
};

export const formatTasksTabs = (x: Task[], f: (value: Task, i: number, x: Task[]) => string) =>
    // eslint-disable-next-line no-sequences
    x.reduce((a: any, b: Task, i: number) => ((a[f(b, i, x)] ||= []).push(b), a), { todo: [], progress: [], completed: [] });

export const taskFormat = (v: Task) => (v.status === 'Not Done' ? 'todo' : v.status === 'In Review' ? 'progress' : 'completed');
