// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import onboardingReducer from './slices/onboarding';
import kycReducer from './slices/kyc';
import refinanceDebtReducer from './slices/refinanceDebt';
import portalReducer from './slices/portal';
import reapplyLoanReducer from './slices/reapplyLoan';
import spainWheelDebtReducer from './slices/spinWheelDebt';
// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    onboarding: onboardingReducer,
    kyc: kycReducer,
    refinanceDebt: refinanceDebtReducer,
    portal: portalReducer,
    reapplyLoan: reapplyLoanReducer,
    spinWheelDebt: spainWheelDebtReducer
});

export default reducer;
