import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// types
import { Document, PortalProps, Task } from 'types/portal';
import axiosServices from 'utils/axios';
import { openSnackbar } from './snackbar';
import { RootState } from 'store';
import { formatTasksTabs, taskFormat } from 'utils/helper';

const initialState: PortalProps = {
    documents: {
        data: [],
        isFetching: false
    },
    tasks: {
        data: {
            todo: [],
            progress: [],
            completed: []
        },
        isFetching: false
    }
};

export const handleDocumentDownload = async (documentId: string, name: string, extension: string) => {
    try {
        const res = await axiosServices.get(`/loans/download-document/${documentId}`, { responseType: 'blob' });
        let fileName = name.split('.')[0];
        let blob = new Blob([res.data]);
        let link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${fileName}.${extension}`;
        link.click();
    } catch (err) {}
};

export const getTradeLineDocuments = createAsyncThunk(
    'getTradeLineDocuments',
    async ({ applicationId }: { applicationId: string }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosServices.get(`/loans/document-info/${applicationId}`);
            const { data } = response;
            return data;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.msg || e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);

export const getTasks = createAsyncThunk(
    'getTasks',
    async ({ applicationId }: { applicationId: string }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosServices.get(`/loans/tasks/${applicationId}`);
            const { data } = response;
            return data;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.msg || e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);

// ==============================|| SLICE - PORTAL ||============================== //

const portal = createSlice({
    name: 'portal',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getTradeLineDocuments.pending, (state: PortalProps) => {
                state.documents.isFetching = true;
            })
            .addCase(getTradeLineDocuments.fulfilled, (state: PortalProps, { payload }: PayloadAction<Document[]>) => {
                state.documents.isFetching = false;
                state.documents.data = payload;
            })
            .addCase(getTradeLineDocuments.rejected, (state: PortalProps) => {
                state.documents.isFetching = false;
            })
            .addCase(getTasks.pending, (state: PortalProps) => {
                state.tasks.isFetching = true;
            })
            .addCase(getTasks.fulfilled, (state: PortalProps, { payload }: PayloadAction<{ items: Task[] }>) => {
                state.tasks.isFetching = false;
                state.tasks.data = Array.isArray(payload.items)
                    ? formatTasksTabs(payload.items, taskFormat)
                    : { todo: [], progress: [], completed: [] };
            })
            .addCase(getTasks.rejected, (state: PortalProps) => {
                state.tasks.isFetching = false;
            });
    }
});

export default portal.reducer;

export const portalSelector = (state: RootState) => state.portal;
