import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router-dom';

// login routing

const Registration = Loadable(lazy(() => import('pages/onboarding')));
const Login = Loadable(lazy(() => import('pages/login')));
const Forgot = Loadable(lazy(() => import('pages/forgotPassword')));
const Article = Loadable(lazy(() => import('pages/article')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <GuestGuard>
            <MinimalLayout />
        </GuestGuard>
    ),
    children: [
        {
            path: '/',
            element: <Login />
        },
        {
            path: '/register',
            element: <Registration />
        },
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/forgot-password',
            element: <Forgot />
        },
        {
            path: '/article/:articleId',
            element: <Article />
        },

        {
            path: '*',
            element: <Navigate to="/" replace />
        }
    ]
};

export default LoginRoutes;
