import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosServices from 'utils/axios';
import { openSnackbar } from './snackbar';
import { RootState } from 'store';
import { KeyedObject } from 'types';

const apiResponse = {
    data: [],
    isFetching: false,
    isError: false,
    isSuccess: false
};
const initialState: KeyedObject = {
    getDigifiApplication: { ...apiResponse, data: {} },
    initialFormData: {}
};

type InitialStateType = typeof initialState;

export const getFinancialDetail = createAsyncThunk(
    'getFinancialDetail',
    async ({ applicationId }: { applicationId: string }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosServices.get(`/loans/application/${applicationId}`);
            const { data } = response;
            return data;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.msg || e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);

const reapplyLoan = createSlice({
    name: 'reapplyLoan',
    initialState,
    reducers: {
        setInitialFormData: (state: InitialStateType, action: PayloadAction<any>) => {
            state.initialFormData = { ...action.payload };
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFinancialDetail.pending, (state: InitialStateType) => {
                state.isFetching = true;
            })
            .addCase(getFinancialDetail.fulfilled, (state: InitialStateType, { payload }: PayloadAction<any>) => {
                state.getDigifiApplication.data = payload;
                state.isFetching = false;
            })
            .addCase(getFinancialDetail.rejected, (state: InitialStateType) => {
                state.isFetching = false;
            });
    }
});

export default reapplyLoan.reducer;
export const reapplyLoanSelector = (state: RootState) => state.reapplyLoan;
export const { setInitialFormData } = reapplyLoan.actions;
