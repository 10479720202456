import { Outlet, useLocation, useNavigate } from 'react-router-dom';
// material-ui
import { Box, Container, CssBaseline } from '@mui/material';

import useConfig from 'hooks/useConfig';
import HeaderLogo from 'ui-component/HeaderLogo';
import Footer from 'ui-component/Footer';
import { useEffect } from 'react';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const { container } = useConfig();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (process.env.REACT_APP_IS_DEV !== 'true') {
            if (location.key === 'default') {
                navigate(-1);
            }
        }
    }, []);

    return (
        <>
            <HeaderLogo />
            <Box sx={{ display: 'flex', bgcolor: '#fff', marginBottom: { xs: '100px', md: '50px' } }}>
                <CssBaseline />

                <Container
                    maxWidth={container ? 'lg' : false}
                    {...(!container && { sx: { bgcolor: ' rgba(255, 255, 255, 0.0001)', px: { xs: 0 } } })}
                >
                    <Outlet />
                </Container>
            </Box>
            <Footer />
        </>
    );
};

export default MainLayout;
