import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axiosServices from 'utils/axios';
import { openSnackbar } from './snackbar';
import { RootState } from 'store';
import { KeyedObject } from 'types';

const apiResponse = {
    data: [],
    isFetching: false,
    isError: false,
    isSuccess: false
};
const initialState: KeyedObject = {
    debtList: apiResponse,
    updatedDebt: apiResponse,
    tradeLineSubmit: apiResponse,
    spinWheelDisconnected: apiResponse,
    selectionType: '',
    initialFormData: {}
};

type InitialStateType = typeof initialState;

export const getSpinWheelDebt = createAsyncThunk('getSpainWheelDebt', async (_, { rejectWithValue, dispatch }) => {
    try {
        const response = await axiosServices.get('/loans/spinwheel/tradelines');
        const { data } = response;
        return data;
    } catch (e: any) {
        dispatch(
            openSnackbar({
                open: true,
                message: e?.msg || e?.ex,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false
            })
        );
        return rejectWithValue(e);
    }
});

export const updateSpinWheelDebt = createAsyncThunk('updateSpinWheelDebt', async ({ obj }: any, { rejectWithValue, dispatch }) => {
    const { tradeLineId, ...newObj } = obj;
    try {
        const response = await axiosServices.put(`/loans/spinwheel/tradelines/${tradeLineId}`, { ...newObj });
        const { data } = response;

        // dispatch(getTradeLines({ applicationId }));
        dispatch(
            openSnackbar({
                open: true,
                message: 'Success',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        return data;
    } catch (e: any) {
        dispatch(
            openSnackbar({
                open: true,
                message: e?.msg || e?.ex,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false
            })
        );
        return rejectWithValue(e);
    }
});

export const tradeLinesSubmit = createAsyncThunk('tradeLinesSubmit', async (obj: any, { rejectWithValue, dispatch }) => {
    try {
        const response = await axiosServices.post(`/loans/spinwheel/tradelines/submit`, { ...obj });
        const { data } = response;

        dispatch(
            openSnackbar({
                open: true,
                message: 'Submitted.',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        return data;
    } catch (e: any) {
        dispatch(
            openSnackbar({
                open: true,
                message: e?.msg || e?.ex,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false
            })
        );
        return rejectWithValue(e);
    }
});

export const spinwheelDisconnect = createAsyncThunk('spinwheelDisconnect', async (args: undefined, { rejectWithValue, dispatch }) => {
    try {
        const response = await axiosServices.delete(`/loans/spinwheel/user`);

        const { data } = response;

        dispatch(
            openSnackbar({
                open: true,
                message: 'Disconnected.',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );

        return data;
    } catch (e: any) {
        dispatch(
            openSnackbar({
                open: true,
                message: e?.msg || e?.ex,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false
            })
        );
        return rejectWithValue(e);
    }
});

const spinWheelDebt = createSlice({
    name: 'spinWheelDebt',
    initialState,
    reducers: {
        setInitialFormData: (state: InitialStateType, action: PayloadAction<any>) => {
            state.initialFormData = { ...action.payload };
        },
        setUpdatedDebtSuccess: (state: KeyedObject) => {
            state.updatedDebt = apiResponse;
        },
        resetTradeLineSubmit: (state: KeyedObject) => {
            state.tradeLineSubmit = apiResponse;
        },
        resetSpinWheelDisconnected: (state: KeyedObject) => {
            state.spinWheelDisconnected = apiResponse;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getSpinWheelDebt.pending, (state: InitialStateType) => {
                state.isFetching = true;
            })
            .addCase(getSpinWheelDebt.fulfilled, (state: InitialStateType, { payload }: PayloadAction<any>) => {
                state.debtList.data = payload?.tradeLines;
                state.debtList.isFetching = false;
                state.debtList.isSuccess = true;
            })
            .addCase(getSpinWheelDebt.rejected, (state: InitialStateType) => {
                state.isFetching = false;
                state.debtList.isSuccess = false;
            })
            .addCase(updateSpinWheelDebt.pending, (state: InitialStateType) => {
                state.updatedDebt.isFetching = true;
            })
            .addCase(updateSpinWheelDebt.fulfilled, (state: InitialStateType, { payload }: PayloadAction<any>) => {
                state.debtList.data = payload;
                state.updatedDebt.isFetching = false;
                state.updatedDebt.isSuccess = true;
            })
            .addCase(updateSpinWheelDebt.rejected, (state: InitialStateType) => {
                state.updatedDebt.isFetching = false;
            })
            .addCase(tradeLinesSubmit.pending, (state: InitialStateType) => {
                state.tradeLineSubmit.isFetching = true;
            })
            .addCase(tradeLinesSubmit.fulfilled, (state: InitialStateType, { payload }: PayloadAction<any>) => {
                state.tradeLineSubmit.isFetching = false;
                state.tradeLineSubmit.isSuccess = true;
            })
            .addCase(tradeLinesSubmit.rejected, (state: InitialStateType) => {
                state.tradeLineSubmit.isFetching = false;
            })
            .addCase(spinwheelDisconnect.pending, (state: InitialStateType) => {
                state.spinWheelDisconnected.isFetching = true;
                state.spinWheelDisconnected.isSuccess = false;
            })
            .addCase(spinwheelDisconnect.fulfilled, (state: InitialStateType, { payload }: PayloadAction<any>) => {
                state.spinWheelDisconnected.isFetching = false;
                state.spinWheelDisconnected.isSuccess = true;
            })
            .addCase(spinwheelDisconnect.rejected, (state: InitialStateType) => {
                state.spinWheelDisconnected.isFetching = false;
                state.spinWheelDisconnected.isSuccess = false;
            });
    }
});

export default spinWheelDebt.reducer;
export const spinWheelDebtSelector = (state: RootState) => state.spinWheelDebt;
export const { setInitialFormData, setUpdatedDebtSuccess, resetTradeLineSubmit, resetSpinWheelDisconnected } = spinWheelDebt.actions;
