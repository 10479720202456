import { PayloadAction, Slice, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import axiosServices from 'utils/axios';
import { openSnackbar } from './snackbar';
import { KeyedObject } from 'types';

const apiResponse = {
    data: [],
    isFetching: false,
    isError: false,
    isSuccess: false
};

type InitialStateType = {
    refinanceDebt: typeof refinanceDebtInitialState;
    tradeLines: (typeof refinanceDebtInitialState)[];
    addDebtsResponse: typeof apiResponse;
    providerList: typeof apiResponse;
    spinWheelToken: typeof apiResponse;
    isSpinWheelConnected: typeof apiResponse;
    isFetching: boolean;
    isError: boolean;
};

const refinanceDebtInitialState = {
    tradeline_type: '',
    tradeline_lender_name: '',
    tradeline_lender_payee: '',
    tradeline_lender_address: '',
    tradeline_lender_city: '',
    tradeline_lender_state: '',
    tradeline_lender_zipcode: '',
    tradeline_account_holder_name: '',
    tradeline_account_number: '',
    tradeline_payment_option: '',
    tradeline_amount: '',
    tradeline_lender_address_line_2: '',
    documents: []
};

const initialState: InitialStateType = {
    refinanceDebt: refinanceDebtInitialState,
    tradeLines: [],
    addDebtsResponse: apiResponse,
    providerList: apiResponse,
    spinWheelToken: apiResponse,
    isSpinWheelConnected: apiResponse,
    isFetching: false,
    isError: false
};

export const getTradeLines = createAsyncThunk(
    'getTradeLine',
    async ({ applicationId }: { applicationId: string }, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosServices.get(`/loans/debt/${applicationId}`);
            const { data } = response;
            return data;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.msg || e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);
export const deleteDebt = createAsyncThunk(
    'deleteDebt',
    async ({ applicationId, id }: KeyedObject, { getState, rejectWithValue, dispatch }) => {
        try {
            const response = await axiosServices.put(`/loans/debt`, { applicationId, id });
            const { data } = response;
            const { updatedData } = data;
            dispatch(
                openSnackbar({
                    open: true,
                    message: 'Debt deleted',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            return updatedData;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.msg || e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);

export const sendDebtData = createAsyncThunk(
    'sendDebtData',
    async ({ applicationId }: KeyedObject, { getState, rejectWithValue, dispatch }) => {
        const {
            refinanceDebt: { refinanceDebt }
        } = getState() as RootState;
        try {
            const response = await axiosServices.post(`/loans/add-debt`, { applicationId, ...refinanceDebt });
            const { data } = response;
            const { msg, updatedTradeLines } = data;
            dispatch(
                openSnackbar({
                    open: true,
                    message: msg,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            return updatedTradeLines;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.msg || e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);

export const sendDebtDetails = createAsyncThunk(
    'sendDebtDetails',
    async ({ applicationId }: { applicationId: string }, { getState, rejectWithValue, dispatch }) => {
        const {
            refinanceDebt: { tradeLines }
        } = getState() as RootState;
        try {
            const response = await axiosServices.post(`/loans/add-debt`, { applicationId, tradeLines });
            const { data } = response;
            const { msg } = data;
            dispatch(
                openSnackbar({
                    open: true,
                    message: msg,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            return data;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.msg || e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);

export const updateDebt = createAsyncThunk(
    'updateDebt',
    async ({ applicationId }: { applicationId: string }, { getState, rejectWithValue, dispatch }) => {
        const {
            refinanceDebt: { refinanceDebt }
        } = getState() as RootState;

        const temp = {
            ...refinanceDebt,
            documents: refinanceDebt.documents.map(({ name, file }: KeyedObject) => ({ name, file }))
        };
        const updatedDocuments = temp.documents.filter((doc: KeyedObject) => doc.file !== '');
        const updatedData = {
            ...temp,
            documents: updatedDocuments
        };
        try {
            const response = await axiosServices.put(`/loans/update-debt`, { applicationId, ...updatedData });
            const { data } = response;
            const { msg } = data;
            dispatch(getTradeLines({ applicationId }));
            dispatch(
                openSnackbar({
                    open: true,
                    message: msg,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            return data;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.msg || e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);

export const getProvider = createAsyncThunk('getProvider', async (_, { rejectWithValue, dispatch }) => {
    try {
        const response = await axiosServices.get('/yodlee/providers');
        const { data } = response;
        return data;
    } catch (e: any) {
        dispatch(
            openSnackbar({
                open: true,
                message: e?.msg || e?.ex,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false
            })
        );
        return rejectWithValue(e);
    }
});
export const getSpinWheelToken = createAsyncThunk('getSpinWheelToken', async (_, { rejectWithValue, dispatch }) => {
    try {
        const response = await axiosServices.get('/loans/spinwheel/access-token');
        const { data } = response;
        return data;
    } catch (e: any) {
        dispatch(
            openSnackbar({
                open: true,
                message: e?.msg || e?.ex,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false
            })
        );
        return rejectWithValue(e);
    }
});
export const onSpinwheelConnectionSuccess = createAsyncThunk(
    'bu/users/onSpinwheelConnectionSuccess',
    async (args: undefined, { rejectWithValue }) => {
        try {
            const response = await axiosServices.post(`/loans/spinwheel/connection`);
            const { data } = response;
            return data;
        } catch (e: any) {
            return rejectWithValue(e);
        }
    }
);
const refinanceDebt: Slice = createSlice({
    name: 'refinanceDebt',
    initialState,
    reducers: {
        clearForm: (state: KeyedObject) => {
            state.refinanceDebt = refinanceDebtInitialState;
        },
        clearSpainWheelToken: (state: KeyedObject) => {
            state.spinWheelToken = apiResponse;
        },
        updateField: (state: KeyedObject, action: PayloadAction<KeyedObject>) => {
            const {
                payload: { key, value }
            } = action;

            state.refinanceDebt[key] = value;
        },
        editDebt: (state: KeyedObject, action: PayloadAction<KeyedObject>) => {
            const { payload } = action;

            state.refinanceDebt = payload;
        },
        addDebtToArray: (state: KeyedObject, action: PayloadAction<KeyedObject>) => {
            const { payload } = action;
            state.tradeLines.push(payload);
        },
        deleteDebtFromArray: (state: KeyedObject, action: PayloadAction<number>) => {
            const index = action.payload;
            state.tradeLines.splice(index, 1);
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(sendDebtData.pending, (state: InitialStateType) => {
                state.addDebtsResponse.isFetching = true;
            })
            .addCase(sendDebtData.fulfilled, (state: InitialStateType, { payload }: PayloadAction<any>) => {
                state.tradeLines = payload;
                state.addDebtsResponse.isSuccess = true;
                state.addDebtsResponse.isFetching = false;
            })
            .addCase(sendDebtData.rejected, (state: InitialStateType, { payload }: PayloadAction<any>) => {
                state.addDebtsResponse.isError = true;
                state.addDebtsResponse.isFetching = false;
            })
            .addCase(deleteDebt.pending, (state: InitialStateType) => {
                state.addDebtsResponse.isFetching = true;
            })
            .addCase(deleteDebt.fulfilled, (state: InitialStateType, { payload }: PayloadAction<any>) => {
                state.tradeLines = payload;
                state.addDebtsResponse.isSuccess = true;
                state.addDebtsResponse.isFetching = false;
            })
            .addCase(deleteDebt.rejected, (state: InitialStateType, { payload }: PayloadAction<any>) => {
                state.addDebtsResponse.isError = true;
                state.addDebtsResponse.isFetching = false;
            })
            .addCase(getTradeLines.pending, (state: InitialStateType) => {
                state.isFetching = true;
            })
            .addCase(getTradeLines.fulfilled, (state: InitialStateType, { payload }: PayloadAction<any>) => {
                state.tradeLines = payload?.tradeLines;
                state.isFetching = false;
            })
            .addCase(getTradeLines.rejected, (state: InitialStateType) => {
                state.isError = true;
                state.isFetching = false;
            })
            .addCase(getProvider.pending, (state: InitialStateType) => {
                state.providerList.isFetching = true;
            })
            .addCase(getProvider.fulfilled, (state: InitialStateType, { payload }: PayloadAction<any>) => {
                state.providerList.data = payload;
                state.providerList.isSuccess = true;
                state.providerList.isFetching = false;
            })
            .addCase(getProvider.rejected, (state: InitialStateType, { payload }: PayloadAction<any>) => {
                state.providerList.isError = true;
                state.providerList.isFetching = false;
            })
            .addCase(getSpinWheelToken.pending, (state: InitialStateType) => {
                state.spinWheelToken.isFetching = true;
            })
            .addCase(getSpinWheelToken.fulfilled, (state: InitialStateType, { payload }: PayloadAction<any>) => {
                state.spinWheelToken = payload.data;
                state.spinWheelToken.isSuccess = true;
                state.spinWheelToken.isFetching = false;
            })
            .addCase(getSpinWheelToken.rejected, (state: InitialStateType, { payload }: PayloadAction<any>) => {
                state.spinWheelToken.isError = true;
                state.spinWheelToken.isFetching = false;
            })
            .addCase(onSpinwheelConnectionSuccess.pending, (state: InitialStateType) => {
                state.isSpinWheelConnected.isFetching = true;
            })
            .addCase(onSpinwheelConnectionSuccess.fulfilled, (state: InitialStateType, { payload }: PayloadAction<any>) => {
                state.isSpinWheelConnected.isSuccess = true;
                state.isSpinWheelConnected.isFetching = false;
            })
            .addCase(onSpinwheelConnectionSuccess.rejected, (state: InitialStateType, { payload }: PayloadAction<any>) => {
                state.isSpinWheelConnected.isError = true;
                state.isSpinWheelConnected.isFetching = false;
            });
    }
});

export default refinanceDebt.reducer;

export const refinanceDebtSelector = (state: RootState) => state.refinanceDebt;

export const { updateField, editDebt, clearForm, addDebtToArray, deleteDebtFromArray, clearSpainWheelToken } = refinanceDebt.actions;
