import React, { createContext, useEffect, useReducer } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT, REGISTER, SET_SMS_VERIFICATION, SET_SPINWHEEL_TRADELINE, VERIFY_CODE } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';

// types
import { KeyedObject } from 'types';
import { InitialLoginContextProps, JWTContextType } from 'types/auth';
import axiosServices from 'utils/axios';
import { dispatch as reduxDispatch } from 'store';
import { setOnboardingData } from 'store/slices/onboarding';
import { UserProfile } from 'types/user-profile';

// constant
const initialState: InitialLoginContextProps = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    currentSection: '',
    smsVerification: false
};

const verifyToken: (st: string) => boolean = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded: KeyedObject = jwtDecode(serviceToken);
    /**
     * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
     */
    return decoded.exp > Date.now() / 1000;
};

export const setSession = (serviceToken?: string | null) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

export const setRefreshToken = async (refreshToken?: string | null) => {
    if (refreshToken) {
        try {
            localStorage.setItem('refreshToken', refreshToken);
        } catch (e) {
            // eslint-disable-next-line no-console
            // console.log(e);
        }
    }
};
export const getRefreshToken: () => void = async () => {
    try {
        const refreshToken = localStorage.getItem('refreshToken');
        // console.log('refreshToken', refreshToken);
        if (refreshToken) {
            return refreshToken;
        }
    } catch (e) {
        return getRefreshToken();
    }
    return getRefreshToken();
};
// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext<JWTContextType | null>(null);

export const JWTProvider = ({ children }: { children: React.ReactElement }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    const init = async () => {
        try {
            const serviceToken = window.localStorage.getItem('serviceToken');
            if (serviceToken && verifyToken(serviceToken)) {
                setSession(serviceToken);
                const response = await axios.get(`/loans/profile`);
                const { data } = response;
                reduxDispatch(setOnboardingData(data));
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: { ...data }
                    }
                });
            } else {
                dispatch({
                    type: LOGOUT
                });
            }
        } catch (err) {
            dispatch({
                type: LOGOUT
            });
        }
    };

    const getConfig = async () => {
        try {
            const smsServiceResponse = await axiosServices.get('/auth/check-config');
            // Handle the response data here
            if (smsServiceResponse?.data?.smsService) {
                dispatch({
                    type: SET_SMS_VERIFICATION,
                    payload: {
                        isLoggedIn: true,
                        smsVerification: smsServiceResponse?.data?.smsService
                    }
                });
            }
        } catch (error) {
            // Handle any errors that occurred during the request
            console.error('Error fetching data: ', error);
        }
    };

    useEffect(() => {
        init();
    }, []);

    const sendEmail = async (email: string) => {
        return axiosServices.post('/auth/send-verification-email', { email });
    };

    const emailStatus = async (email: string) => {
        return axiosServices.get(`auth/check-email?email=${encodeURIComponent(email)}`);
    };

    const sendPhone = async (phoneNumber: string) => {
        return axiosServices.post('/auth/send-verification-sms', { phoneNumber });
    };
    const verifyPhoneEmail = (obj: Object) => {
        return axiosServices.post('/auth/verify', obj);
    };

    const register = (obj: KeyedObject) => {
        return axiosServices.post('/loans/register', obj);
    };

    const registerUser = async (user: UserProfile) => {
        const { token, refreshToken } = user;
        await setSession(token);
        await setRefreshToken(refreshToken);

        dispatch({
            type: REGISTER,
            payload: {
                isLoggedIn: true,
                user
            }
        });
    };

    const login = async (email: string, password: string) => {
        // call login api here
        const response = await axios.post('/auth/login', { email, password });
        const { token, refreshToken } = response.data;
        setSession(token);
        setRefreshToken(refreshToken);
        init();
        dispatch({
            type: LOGIN,
            payload: {
                isLoggedIn: true
            }
        });
    };
    const updateProfile = async (obj: KeyedObject) => {
        return axiosServices.put(`/loans/profile`, obj);
    };

    const forgotPassword = (email: string) => {
        return axios.post('/auth/reset-password-email', { email });
    };
    const changePassword = (data: KeyedObject) => {
        const { oldPassword, newPassword } = data;
        return axiosServices.post(`/profile/password`, { oldPassword, newPassword });
    };

    const resetPassword = (data: KeyedObject) => {
        const { email, password, code } = data;
        return axios.post('/auth/reset-password', { email, password, code });
    };
    const setTradeLineSource = async (user: any) => {
        dispatch({
            type: SET_SPINWHEEL_TRADELINE,
            payload: { user: user?.user, isLoggedIn: true }
        });
    };

    const verifyCode = async () => {
        dispatch({
            type: VERIFY_CODE,
            payload: { isLoggedIn: false, currentSection: 'reset' }
        });
    };

    const logout = () => {
        setSession(null);
        setRefreshToken(null);
        dispatch({ type: LOGOUT });
    };

    // const updateProfile = () => { };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{
                ...state,
                sendPhone,
                registerUser,
                changePassword,
                verifyPhoneEmail,
                sendEmail,
                emailStatus,
                login,
                logout,
                register,
                resetPassword,
                forgotPassword,
                verifyCode,
                init,
                updateProfile,
                setTradeLineSource,
                getConfig
            }}
        >
            {children}
        </JWTContext.Provider>
    );
};

export default JWTContext;
