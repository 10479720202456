import { IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { ReactComponent as Logo } from '../assets/svg/logo.svg';
import { ReactComponent as ProfileLogo } from '../assets/svg/profileIcon.svg';
import useAuth from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { clearState } from 'store/slices/onboarding';
import { dispatch } from 'store';

const HeaderLogo = () => {
    const { isLoggedIn, logout } = useAuth();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate();
    return (
        <div
            style={{
                boxShadow: '0px 15px 30px rgba(237, 237, 237, 0.25)',
                width: '100%',
                height: '96px',
                position: 'sticky',
                top: 0,
                background: '#fff',
                zIndex: 12,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
            }}
        >
            <div style={{ width: '106px' }} />
            <Logo />
            {isLoggedIn ? (
                <>
                    <IconButton onClick={handleClick} sx={{ mr: '52px' }}>
                        <ProfileLogo />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button'
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                navigate('/settings');
                            }}
                        >
                            Settings
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                logout();
                                dispatch(clearState());
                            }}
                        >
                            Logout
                        </MenuItem>
                    </Menu>
                </>
            ) : (
                <div style={{ width: '106px' }} />
            )}
        </div>
    );
};

export default HeaderLogo;
