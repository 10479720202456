import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import axiosServices from 'utils/axios';
import { openSnackbar } from './snackbar';
import { KeyedObject } from 'types';

// interface InitialStateTypes extends UserProfile {
//     dob: string | null;
//     password: string;
//     formattedPhone: string;
//     pai: string;
//     mhp: string;
//     cop: string;
//     isFetching: boolean;
//     borrowerResponse: KeyedObject | null;
//     applicationResponse: KeyedObject | null;
//     isError: boolean;
//     errorMessage: string;
//     decisionResponse: string;
//     employerStreetAddress: string;
//     employerAddressLine2: string;
//     employerCity: string;
//     employerState: string;
//     employerZip: string;
//     employerCountry: string;
//     offerAccepted: boolean;
// }

export const sendApplication = createAsyncThunk('onboarding/sendApplication', async (obj: KeyedObject, { rejectWithValue, dispatch }) => {
    try {
        const response = await axiosServices.post(`/loans/application`, obj);
        const { data } = response;
        return data;
    } catch (e: any) {
        dispatch(
            openSnackbar({
                open: true,
                message: e?.msg || e?.ex,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false
            })
        );
        return rejectWithValue(e);
    }
});

export const sendBorrowerDetails = createAsyncThunk(
    'onboarding/sendBorrowerDetails',
    async (obj: KeyedObject, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosServices.post(`/loans/borrower`, obj);
            const { data } = response;
            return data;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.msg || e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);
export const updateBorrowerDetails = createAsyncThunk(
    'onboarding/updateBorrowerDetails',
    async (obj: KeyedObject, { rejectWithValue, dispatch }) => {
        try {
            const response = await axiosServices.put(`/loans/borrower`, obj);
            const { data } = response;
            dispatch(
                openSnackbar({
                    open: true,
                    message: data.msg || 'Borrower updated.',
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    },
                    close: false
                })
            );
            return data;
        } catch (e: any) {
            dispatch(
                openSnackbar({
                    open: true,
                    message: e?.msg || e?.ex,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    variant: 'alert',
                    alert: {
                        color: 'error'
                    },
                    close: false
                })
            );
            return rejectWithValue(e);
        }
    }
);

export const sendDecision = createAsyncThunk('onboarding/sendDecision', async (obj: KeyedObject, { rejectWithValue, dispatch }) => {
    try {
        const response = await axiosServices.post(`/loans/decision`, obj);

        const { data } = response;
        return data;
    } catch (e: any) {
        dispatch(
            openSnackbar({
                open: true,
                message: e?.msg || e?.ex,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false,
                autoHideDuration: 9000
            })
        );
        return rejectWithValue(e);
    }
});

export const acceptOffer = createAsyncThunk('onboarding/acceptOffer', async (obj: KeyedObject, { rejectWithValue, dispatch }) => {
    try {
        const response = await axiosServices.post(`/loans/accept-offer`, obj);
        const { data } = response;
        dispatch(
            openSnackbar({
                open: true,
                message: 'Submitted.',
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        return data;
    } catch (e: any) {
        dispatch(
            openSnackbar({
                open: true,
                message: e?.msg || e?.ex,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false
            })
        );
        return rejectWithValue(e);
    }
});

export const updateProfile = createAsyncThunk('onboarding/updateProfile', async (obj: KeyedObject, { rejectWithValue, dispatch }) => {
    try {
        const response = await axiosServices.post(`/loans/profile`, obj);
        const { data } = response;
        return data;
    } catch (e: any) {
        dispatch(
            openSnackbar({
                open: true,
                message: e?.msg || e?.ex,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false
            })
        );
        return rejectWithValue(e);
    }
});

const initialState: KeyedObject = {
    firstName: '',
    lastName: '',
    middleName: '',
    suffix: '',
    email: '',
    streetAddress: '',
    addressLine2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    dob: null,
    password: '',
    formattedPhone: '',
    employerName: '',
    jobTitle: '',
    pai: '',
    mhp: '',
    cop: '',
    citizenshipStatus: '',
    ssn: '',
    isFetching: false,
    isSuccess: false,
    borrowerResponse: null,
    applicationResponse: null,
    isError: false,
    errorMessage: '',
    decisionApiError: false,
    decisionResponse: '',
    employerStreetAddress: '',
    employerAddressLine2: '',
    employerCity: '',
    employerState: '',
    employerZip: '',
    country: 'USA',
    employerCountry: 'USA',
    offerAccepted: false,
    submitted: false,
    rent_or_own: ''
};

const onboardingSlice = createSlice({
    name: 'onboarding',
    initialState,
    reducers: {
        updateField: (state: typeof initialState, action: PayloadAction<KeyedObject>) => {
            const {
                payload: { key, value }
            } = action;
            state[key] = value;
        },
        clearState: (state: typeof initialState) => {
            state.firstName = '';
            state.lastName = '';
            state.middleName = '';
            state.suffix = '';
            state.email = '';
            state.streetAddress = '';
            state.addressLine2 = '';
            state.city = '';
            state.state = '';
            state.zip = '';
            state.phone = '';
            state.dob = null;
            state.password = '';
            state.formattedPhone = '';
            state.employerName = '';
            state.jobTitle = '';
            state.pai = '';
            state.mhp = '';
            state.cop = '';
            state.citizenshipStatus = '';
            state.ssn = '';
            state.isFetching = false;
            state.isSuccess = false;
            state.borrowerResponse = null;
            state.applicationResponse = null;
            state.isError = false;
            state.decisionApiError = false;
            state.errorMessage = '';
            state.decisionResponse = '';
            state.employerStreetAddress = '';
            state.employerAddressLine2 = '';
            state.employerCity = '';
            state.employerState = '';
            state.employerZip = '';
            state.country = 'USA';
            state.employerCountry = 'USA';
            state.offerAccepted = false;
            state.submitted = false;
            state.rent_or_own = '';
        },
        revertChanges: (state: typeof initialState, action: PayloadAction<KeyedObject>) => {
            const { payload } = action;

            const {
                firstName,
                lastName,
                suffix,
                middleName,
                dob,
                streetAddress,
                email,
                city,
                addressLine2,
                state: countryState,
                zip,
                phone,
                employerName,
                jobTitle,
                citizenshipStatus,
                ssn,
                employerStreetAddress,
                employerAddressLine2,
                employerCity,
                employerState,
                employerZip,
                rent_or_own
            } = payload;

            state.firstName = firstName;
            state.lastName = lastName;
            state.suffix = suffix;
            state.middleName = middleName;
            state.email = email;
            state.streetAddress = streetAddress;
            state.addressLine2 = addressLine2;
            state.city = city;
            state.state = countryState;
            state.zip = zip;
            state.phone = phone;
            state.dob = dob;
            state.password = '';
            state.employerName = employerName;
            state.jobTitle = jobTitle;
            state.employerStreetAddress = employerStreetAddress;
            state.employerAddressLine2 = employerAddressLine2;
            state.employerCity = employerCity;
            state.employerState = employerState;
            state.employerZip = employerZip;
            state.citizenshipStatus = citizenshipStatus;
            state.ssn = ssn;
            state.rent_or_own = rent_or_own;
        },
        setOnboardingData: (state: typeof initialState, action) => {
            const { payload } = action;
            const {
                firstName,
                lastName,
                suffix,
                middleName,
                date_of_birth,
                streetAddress,
                email,
                city,
                addressLine2,
                state: countryState,
                zip,
                phone,
                employerName,
                jobTitle,
                citizenshipStatus,
                ssn,
                employerStreetAddress,
                employerAddressLine2,
                employerCity,
                employerState,
                employerZip,
                rent_or_own
            } = payload;
            state.firstName = firstName;
            state.lastName = lastName;
            state.suffix = suffix;
            state.middleName = middleName;
            state.email = email;
            state.streetAddress = streetAddress;
            state.addressLine2 = addressLine2;
            state.city = city;
            state.state = countryState;
            state.zip = zip;
            state.phone = phone;
            state.dob = date_of_birth;
            state.password = '';
            state.employerName = employerName;
            state.jobTitle = jobTitle;
            state.employerStreetAddress = employerStreetAddress;
            state.employerAddressLine2 = employerAddressLine2;
            state.employerCity = employerCity;
            state.employerState = employerState;
            state.employerZip = employerZip;
            state.citizenshipStatus = citizenshipStatus;
            state.ssn = ssn;
            state.rent_or_own = rent_or_own;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendBorrowerDetails.pending, (state: typeof initialState) => {
                state.isFetching = true;
            })
            .addCase(sendBorrowerDetails.fulfilled, (state: typeof initialState, { payload }: PayloadAction<any>) => {
                state.borrowerResponse = payload;
                state.isFetching = false;
            })
            .addCase(sendBorrowerDetails.rejected, (state: typeof initialState, { payload }: PayloadAction<any>) => {
                state.isError = true;
                state.isFetching = false;
                state.errorMessage = payload;
            })
            .addCase(sendApplication.pending, (state: typeof initialState) => {
                state.isFetching = true;
                state.isSuccess = false;
            })
            .addCase(sendApplication.fulfilled, (state: typeof initialState, { payload }: PayloadAction<any>) => {
                state.applicationResponse = payload;
                state.isSuccess = true;
                state.isFetching = false;
            })
            .addCase(sendApplication.rejected, (state: typeof initialState, { payload }: PayloadAction<any>) => {
                state.isError = true;
                state.isFetching = false;
                state.isSuccess = false;
                state.errorMessage = payload;
            })
            .addCase(sendDecision.pending, (state: typeof initialState) => {
                state.isFetching = true;
            })
            .addCase(sendDecision.fulfilled, (state: typeof initialState, { payload }: PayloadAction<any>) => {
                state.decisionResponse = payload;
                state.submitted = true;
                state.isFetching = false;
            })
            .addCase(sendDecision.rejected, (state: typeof initialState, { payload }: PayloadAction<any>) => {
                state.isError = true;
                state.isFetching = false;
                state.submitted = true;
                state.errorMessage = payload;
                state.decisionApiError = true;
            })
            .addCase(acceptOffer.pending, (state: typeof initialState) => {
                state.isFetching = true;
            })
            .addCase(acceptOffer.fulfilled, (state: typeof initialState, { payload }: PayloadAction<any>) => {
                state.offerAccepted = true;
                state.isFetching = false;
            })
            .addCase(acceptOffer.rejected, (state: typeof initialState, { payload }: PayloadAction<any>) => {
                state.isError = true;
                state.isFetching = false;
                state.errorMessage = payload;
            })
            .addCase(updateProfile.pending, (state: typeof initialState) => {
                state.isFetching = true;
            })
            .addCase(updateProfile.fulfilled, (state: typeof initialState, { payload }: PayloadAction<any>) => {
                state.offerAccepted = true;
                state.isFetching = false;
            })
            .addCase(updateProfile.rejected, (state: typeof initialState, { payload }: PayloadAction<any>) => {
                state.isError = true;
                state.isFetching = false;
                state.errorMessage = payload;
            });
    }
});

export default onboardingSlice.reducer;

export const onboardingSelector = (state: RootState) => state.onboarding;

export const { updateField, setOnboardingData, revertChanges, clearState } = onboardingSlice.actions;
