import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router-dom';

// sample page routing
const OffersHome = Loadable(lazy(() => import('pages/offersHome')));
const AuthenticatedRegistration = Loadable(lazy(() => import('pages/onboarding')));
const RootRouteCheck = Loadable(lazy(() => import('pages/rootRouteCheck')));
const RefinanceDebt = Loadable(lazy(() => import('pages/RefinanceDebt')));
const Kyc = Loadable(lazy(() => import('pages/Kyc')));
const Approved = Loadable(lazy(() => import('pages/approved')));
const Congratulations = Loadable(lazy(() => import('pages/congratulation')));
const Dashboard = Loadable(lazy(() => import('pages/dashboard')));
const Settings = Loadable(lazy(() => import('pages/settings')));
const ChangePassword = Loadable(lazy(() => import('pages/changePassword')));
const ChangePhone = Loadable(lazy(() => import('pages/changePhone')));
const ReapplyLoan = Loadable(lazy(() => import('pages/reapplyLoan')));
const FinancialDetails = Loadable(lazy(() => import('pages/financialDetails')));
const NotEligible = Loadable(lazy(() => import('pages/notEligible')));
const FastSlow = Loadable(lazy(() => import('pages/fastSlow')));
const SpainWheelDebt = Loadable(lazy(() => import('pages/spainWheelDebt')));

// const NotEligible = Loadable(lazy(() => import('pages/notEligible')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        { path: '/', element: <RootRouteCheck /> },
        { path: '/home', element: <RootRouteCheck /> },
        {
            path: '/offers',
            element: <OffersHome />
        },
        {
            path: '/not-eligible',
            element: <NotEligible />
        },
        {
            path: '/user-information',
            element: <AuthenticatedRegistration authenticated />
        },
        {
            path: '/refinance-debt',
            element: <RefinanceDebt />
        },
        {
            path: '/loan-type-selection',
            element: <FastSlow />
        },
        {
            path: '/refinance-debt-fast-way',
            element: <SpainWheelDebt />
        },
        {
            path: '/kyc',
            element: <Kyc />
        },
        {
            path: '/approved',
            element: <Approved />
        },
        {
            path: '/congratulations',
            element: <Congratulations />
        },
        {
            path: '/settings',
            element: <Settings />
        },
        {
            path: '/change-password',
            element: <ChangePassword />
        },
        {
            path: '/change-phone',
            element: <ChangePhone />
        },
        {
            path: '/financial-details',
            element: <FinancialDetails />
        },
        { path: '/portal', element: <Dashboard /> },
        {
            path: '/reapply-loan',
            element: <ReapplyLoan />
        },
        {
            path: '*',
            element: <Navigate to="/" replace />
        }
    ]
};

export default MainRoutes;
