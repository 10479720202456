import { Link, Stack, Typography } from '@mui/material';

const Footer = () => {
    return (
        <Stack
            sx={{
                background: '#F8F8F8',
                position: 'fixed',
                minHeight: '40px',
                width: '100%',
                display: 'fixed',
                bottom: '0',
                justifyContent: 'center',
                alignItems: 'center',
                padding: { xs: '10px', md: '0px' },
                textAlign: 'center',
                zIndex: 1111
            }}
        >
            <Typography component="span" sx={{ color: '#5B616B', fontSize: '12px' }}>
                All loans are unsecured, fully amortizing personal loans. Eligibility for a loan is not guaranteed. Please refer to our
                <Link
                    href="https://www.getbrightup.com/terms-and-conditions"
                    underline="hover"
                    color="inherit"
                    target="blank"
                    sx={{ color: '#002443', fontWeight: '700' }}
                >
                    {' '}
                    Terms & Conditions
                </Link>{' '}
                and{' '}
                <Link
                    href="https://www.getbrightup.com/privacy-policy"
                    underline="hover"
                    color="inherit"
                    target="blank"
                    sx={{ color: '#002443', fontWeight: '700' }}
                >
                    Privacy Policy
                </Link>{' '}
                for further information.
            </Typography>
        </Stack>
    );
};

export default Footer;
