import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import axiosServices from 'utils/axios';
import { openSnackbar } from './snackbar';
import { KeyedObject } from 'types';
// import axiosServices from 'utils/axios';

type InitialStateType = {
    isSuccess: boolean;
    isFetching: boolean;
    isError: boolean;
    kyc: KeyedObject;
};
const kyc = {
    applicationId: '',
    documentType: 'KYC',
    documents: [
        {
            name: '',
            file: '',
            type: '',
            extension: '',
            documentGroup: 'proof_of_address'
        },
        {
            name: '',
            file: '',
            type: '',
            extension: '',
            documentGroup: 'government_id'
        },
        {
            name: '',
            file: '',
            type: 'social-security-number',
            extension: '',
            documentGroup: 'social_security_number'
        },
        {
            name: '',
            file: '',
            type: 'pay-stubs',
            extension: '',
            documentGroup: 'pay_stubs'
        },
        {
            name: '',
            file: '',
            type: 'pay-stubs2',
            extension: '',
            documentGroup: 'pay_stubs'
        }
    ]
};

const initialState: InitialStateType = {
    isFetching: false,
    isError: false,
    isSuccess: false,
    kyc
};
// eslint-disable-next-line @typescript-eslint/no-shadow
export const uploadDocs = createAsyncThunk('kyc/uploadDocuments', async (kyc: KeyedObject, { rejectWithValue, dispatch }) => {
    try {
        const modifiedDocuments = kyc.documents.map(({ ...documentData }: KeyedObject) => documentData);

        // Create a new object without the 'type' key in the documents array
        const postData = {
            ...kyc,
            documents: modifiedDocuments
        };

        const response = await axiosServices.post(`/loans/application/documents`, postData);
        const { data } = response;

        dispatch(
            openSnackbar({
                open: true,
                message: data.msg,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'success'
                },
                close: false
            })
        );
        return data;
    } catch (e: any) {
        dispatch(
            openSnackbar({
                open: true,
                message: e?.msg || e?.ex,
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                variant: 'alert',
                alert: {
                    color: 'error'
                },
                close: false
            })
        );
        return rejectWithValue(e);
    }
});
//i will refactor this later
const kycSlice = createSlice({
    name: 'kyc',
    initialState,
    reducers: {
        updateField: (state: InitialStateType, action: PayloadAction<KeyedObject>) => {
            const {
                payload: { index, key, value }
            } = action;

            let temp = state.kyc.documents[index];

            temp[key] = value;
            state.kyc.documents[index] = temp;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadDocs.pending, (state: InitialStateType) => {
                state.isFetching = true;
            })
            .addCase(uploadDocs.fulfilled, (state: InitialStateType) => {
                state.isFetching = false;
                state.isSuccess = true;
            })
            .addCase(uploadDocs.rejected, (state: InitialStateType) => {
                state.isError = true;
                state.isFetching = false;
            });
    }
});

export default kycSlice.reducer;

export const { updateField } = kycSlice.actions;

export const kycSelector = (state: RootState) => state.kyc;
